import * as React from "react";

import { Add, Delete } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import InputDefault from "../../../atomic/atoms/input.atoms";
import NotFound from "../../../atomic/organisms/NotFound.organisms";

const InputRow = ({
  row,
  column,
  handleChange,
  handleDeleteRow,
  handleSelectedRow,
  idx,
  isReadMode,
}) => {
  const value = row[column.id];

  if (column?.input === "check") {
    return (
      <Checkbox
        onChange={() => handleSelectedRow(idx, column?.id)}
        checked={value}
      />
    );
  }

  if (column?.format) {
    return column.format(value);
  }

  if (isReadMode) {
    return (
      <div className="p-2 rounded-lg relative">
        <p className="text-xs">{value}</p>
      </div>
    );
  }

  if (column.id === "delete") {
    return (
      <IconButton color="secondary" onClick={() => handleDeleteRow(idx)}>
        <Delete />
      </IconButton>
    );
  }

  if (column?.input === "text" || column?.input === "number") {
    return (
      <InputDefault
        value={value}
        type={column?.input || "text"}
        onChange={(e) => handleChange(e, idx)}
        title={column.label}
        name={column.id}
      />
    );
  }

  if (column?.input === "date") {
    return (
      <InputDefault
        value={value}
        type={column?.input}
        onChange={(e) => handleChange(e, idx)}
        title={column.label}
        name={column.id}
      />
    );
  }

  if (column?.input === "time") {
    return (
      <InputDefault
        value={value}
        type={column?.input}
        onChange={(e) => handleChange(e, idx)}
        title={column.label}
        name={column.id}
      />
    );
  }

  if (column?.input === "select") {
    return (
      <InputDefault
        value={value}
        type={column?.input || "select"}
        onChange={(e) => handleChange(e, idx)}
        title={column.label}
        name={column.id}
        options={column.options}
      />
    );
  }



  return value;
};

const DynamicTable = (props) => {
  let {
    columns = [],
    rows = [],
    setRows,
    addRow = false,
    isReadMode,
    load = false,
  } = props;

  if (isReadMode) {
    columns = columns.filter((column) => column.id !== "delete");
  }

  const handleAddRow = () => {
    const newRow = {};

    columns.forEach((column) => {
      newRow[column.id] = "";
    });

    setRows([...rows, newRow]);
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;

    const newRowData = [...rows];
    newRowData[id][name] = value;

    setRows(newRowData);
  };

  const handleDeleteRow = (id) => {
    const newRowData = [...rows];
    newRowData.splice(id, 1);

    setRows(newRowData);
  };

  const handleSelectedRow = (id, columnName) => {
    const newRowData = [...rows];
    newRowData[id][columnName] = !newRowData[id][columnName];

    setRows(newRowData);
  };

  if (load) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress style={{ color: "#e0004d", marginBottom: 5 }} />
        <div style={{ fontSize: "18px", color: "#e0004d" }}>Cargando</div>
      </div>
    );
  }

  return (
    <TableContainer sx={{ maxHeight: 550, border: "1px solid #e0e0e0" }}>
      <Table aria-label="sticky table" size={"small"} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => {
              return (
                <TableCell
                  key={idx}
                  align={column.align}
                  style={{ width: column.minWidth }}
                  className="!font-semibold !text-xs !text-gray-800"
                >
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow className="!text-center">
              <TableCell colSpan={columns.length}>
                <NotFound title="No se encontraron registros." />
              </TableCell>
            </TableRow>
          )}

          {rows.map((row, idx) => (
            <TableRow key={idx}>
              {columns.map((column, rowIdx) => (
                <TableCell
                  align={column.align}
                  style={{ width: column.minWidth }}
                  key={rowIdx}
                >
                  <InputRow
                    isReadMode={isReadMode}
                    idx={idx}
                    row={row}
                    column={column}
                    handleChange={handleChange}
                    handleDeleteRow={handleDeleteRow}
                    handleSelectedRow={handleSelectedRow}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {addRow && !isReadMode && (
            <TableRow>
              <TableCell align={"center"} colSpan={columns.length}>
                <Button
                  variant="text"
                  color="secondary"
                  fullWidth
                  onClick={handleAddRow}
                >
                  <Add />
                  Agregar
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default DynamicTable;
